<section>
  <div class="container table-responsive">
    <h1>
      Order For <strong>{{ clientName }}</strong>
    </h1>

    <div class="mb-4 d-flex flex-wrap">
      <button (click)="pay()" class="btn payed">Confirm Payment</button>
      <button (click)="delivered()" class="btn delivered">
        Confirm Delivery
      </button>
      <button
        color="warn"
        data-bs-toggle="modal"
        data-bs-target="#DeleteCategory"
        [disabled]="cancelButtonStatus"
        class="btn btn-danger"
      >
        Cancel Order
      </button>

      <button
        (click)="getDataForEditModel()"
        data-bs-toggle="modal"
        data-bs-target="#EditOrder"
        [disabled]="cancelButtonStatus"
        class="btn btn-primary"
      >
        Edit Order
      </button>
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Date</th>
          <th scope="col">Status</th>
          <th scope="col">Address</th>
          <th scope="col">Mobile</th>
          <th scope="col">Paid</th>
          <th scope="col">Delivered</th>
          <th scope="col">Fees</th>
          <th scope="col">Total Price</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of order_arr">
          <td>{{ item.issue_date | date : "EEEE d MMMM y hh:mm " }}</td>

          <td *ngIf="item?.cashed == true">Cash On Delivery</td>
          <td *ngIf="item?.cashed == false">Vodafone Cash</td>
          <td>
            {{
              item.address
                ? item?.address?.address + " - " + item?.address?.city
                : "No address"
            }}
          </td>
          <td>
            {{
              orderPhoneNumber ? orderPhoneNumber : item?.client?.phone_number
            }}
          </td>
          <td *ngIf="item.paid == true">Yes</td>
          <td *ngIf="item?.paid == false">No</td>
          <td *ngIf="item?.delivered == false">No</td>
          <td *ngIf="item?.delivered == true">Yes</td>
          <td>{{ item?.fees }} <strong>LE</strong></td>
          <td>{{ item?.total_price }} <strong>LE</strong></td>
        </tr>
      </tbody>
    </table>

    <hr />
    <div *ngIf="product_arr?.length">
      <h3>Products</h3>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Price per piece</th>
            <th scope="col">Quantities</th>
            <th scope="col">Total Price</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of product_arr">
            <td>{{ item?.ordersproducts?.id }}</td>
            <td>{{ item?.ordersproducts?.name }}</td>
            <td>{{ item?.ordersproducts?.price }} <strong>LE</strong></td>
            <td>{{ item?.ammount }}</td>
            <td>
              {{ item?.ammount * item?.ordersproducts?.price }}
              <strong>LE</strong>
            </td>
          </tr>
        </tbody>
      </table>
      <hr *ngIf="packagesArr?.length != 0" />
    </div>
    <div *ngIf="packagesArr?.length">
      <h3>Packages</h3>
      <table class="table table-striped" *ngFor="let item of packagesArr">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Date</th>
            <th scope="col">Quantities</th>
            <th scope="col-6">Products</th>
            <th scope="col">Total Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ item?.orderspackages?.id }}</td>
            <td>{{ item?.orderspackages?.name }}</td>
            <td>
              {{ item?.orderspackages?.issue_date | date : "EEEE d MMMM y " }}
            </td>
            <td>{{ item?.ammount }}</td>
            <td>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style="cursor: pointer"
                    *ngFor="let pack of item?.orderspackages?.ProductPackages"
                  >
                    <td>{{ pack?.products?.name }}</td>
                    <td>{{ pack?.amount }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              {{ item?.orderspackages?.total_price * item?.ammount }}
              <strong>LE</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>

<div
  class="modal fade"
  id="DeleteCategory"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div>
          <h1>Are You Sure Delete this Order</h1>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>

          <button
            (click)="DeleteOrder()"
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-primary"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="EditOrder"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-between">
        <strong>Edit Order</strong>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="closePrompt()"
        ></button>
      </div>
      <div class="modal-body">
        <div>
          <div class="d-flex justify-content-between">
            <p>
              {{
                orderProductsForEditing?.length > 1
                  ? "Current Products:"
                  : "Current Product:"
              }}
            </p>
            <small class="updateTips"
              >If you make the amount <strong>ZERO</strong> the product will be
              removed</small
            >
          </div>

          <div>
            <table class="table-style">
              <thead *ngIf="product_arr?.length">
                <tr>
                  <th>Product name</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of orderProductsForEditing; let i = index">
                  <td>
                    {{ item?.ordersproducts?.name }}
                  </td>
                  <td
                    class="d-flex justify-content-between align-items-center"
                    [formGroup]="currentProductForm"
                  >
                    <input
                      [formControl]="currentProductAmountControls[i]"
                      type="number"
                      value="{{ item?.ammount }}"
                      class="form-control"
                      (change)="
                        changeProductAmount(
                          item?.ordersproducts?.id,
                          currentProductAmountControls[i].value
                        )
                      "
                    />
                  </td>
                </tr>
              </tbody>

              <thead *ngIf="packagesArr.length">
                <tr>
                  <th>Package name</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of orderPackagesForEditing; let i = index">
                  <td>
                    {{ item?.orderspackages?.name }}
                  </td>
                  <td
                    class="d-flex justify-content-between align-items-center"
                    [formGroup]="currentProductForm"
                  >
                    <input
                      [formControl]="currentPackageAmountControls[i]"
                      type="number"
                      value="{{ item?.ammount }}"
                      class="form-control"
                      (change)="
                        changePackageAmount(
                          item?.orderspackages?.id,
                          currentPackageAmountControls[i].value
                        )
                      "
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr />

        <div *ngIf="packImageStatus">
          <div>
            <p>Updated products:</p>
          </div>
          <div class="form-group">
            <div class="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Choose Category
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a
                    class="dropdown-item"
                    (click)="getCatId(item)"
                    *ngFor="let item of cat_arr"
                    >{{ item.name }}</a
                  >
                </li>
              </ul>
            </div>
            <p style="margin-top: 5px; margin-left: 5px" *ngIf="catName">
              {{ catName }}
            </p>
          </div>
          <div class="form-group" *ngIf="status_prod">
            <div class="dropdown" *ngIf="ifProd">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Choose Product
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li *ngFor="let item of prod_arr">
                  <a
                    class="dropdown-item"
                    *ngIf="item.quantity > 0"
                    (click)="choseProd(item)"
                  >
                    {{ item.name }}</a
                  >
                </li>
              </ul>
            </div>
            <p *ngIf="ifProd" style="margin-top: 5px; margin-left: 5px">
              {{ prodName }}
            </p>
            <p *ngIf="!ifProd">No Product Found in this Category</p>
          </div>
          <div class="form-group" *ngIf="ifProd">
            <label>Product Quantity</label>
            <input type="number" [(ngModel)]="Quantity" class="form-control" />
          </div>
          <button *ngIf="ifProd" class="btn btn-success" (click)="add()">
            Add product
          </button>

          <hr *ngIf="ifProd" />
          <div>
            <!-- <ul>
              <li *ngFor="let item of arrayChoiceProduct">
                {{ item }}
              </li>
            </ul> -->

            <table class="table-style" *ngIf="arrayChoiceProduct?.length">
              <thead>
                <tr>
                  <th>Product name</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of arrayChoiceProduct">
                  <td>
                    {{ item?.prodName }}
                  </td>
                  <td>
                    {{ item?.amount }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div *ngIf="packImageStatus">
          <div>
            <p>Updated packages:</p>
          </div>
          <div class="form-group">
            <div class="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Choose package
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a
                    class="dropdown-item"
                    (click)="selectPackageFromList(item)"
                    *ngFor="let item of package_arr"
                    >{{ item.name }}</a
                  >
                </li>
              </ul>
            </div>
            <p style="margin-top: 5px; margin-left: 5px" *ngIf="packageName">
              {{ packageName }}
            </p>
          </div>

          <div class="form-group" *ngIf="ifPackage">
            <label>Package Quantity</label>
            <input
              type="number"
              [(ngModel)]="packageQuantity"
              class="form-control"
            />
          </div>
          <button
            *ngIf="ifPackage"
            class="btn btn-success"
            (click)="addPackage()"
          >
            Add package
          </button>

          <!-- <hr *ngIf="ifPackage" /> -->

          <div>
            <table class="table-style" *ngIf="arrayChoicePackage?.length">
              <thead>
                <tr>
                  <th>Package name</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of arrayChoicePackage">
                  <td>
                    {{ item?.packageName }}
                  </td>
                  <td>
                    {{ item?.amount }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div *ngIf="!packImageStatus">
                  <label>Pack Image</label>
                  <input (change)="uploadFile($event)" type="file" class="form-control">
              </div> -->
      </div>

      <hr />
      <div class="modal-body">
        <div>
          <p>Current Address:</p>
          <div>
            <p class="updateTips" *ngIf="!orderAddressForEditing">
              There is no address for this orders.
            </p>
            <strong *ngIf="orderAddressForEditing">
              {{
                orderAddressForEditing?.address +
                  " - " +
                  orderAddressForEditing?.city +
                  " - " +
                  orderAddressForEditing?.governorate
              }}</strong
            >
          </div>
        </div>
        <hr />

        <div *ngIf="packImageStatus">
          <div class="d-flex justify-content-between">
            <p>Updated Address:</p>
            <small class="updateTips"
              >New <strong>address</strong> will replace the current
              address</small
            >
          </div>
          <button
            *ngIf="!showChangingAddress && !showAddingNewAddressForm"
            (click)="changeAddress()"
            type="button"
            class="btn btn-success mb-2 small-button"
          >
            Change address
          </button>
        </div>
      </div>

      <div
        *ngIf="showChangingAddress && clientAddressForUpdatingAddress"
        class="addressCompleteDiv"
      >
        <div
          *ngFor="let address of clientAddressForUpdatingAddress"
          class="addressesDiv"
        >
          <input
            class="me-2"
            type="radio"
            id="radio_{{ address.id }}"
            [value]="address?.id"
            name="address"
            (change)="selectAddress(address?.id)"
          />
          <label for="radio_{{ address.id }}">
            <div class="d-flex align-items-center">
              <strong
                >{{ address?.city | titlecase
                }}<small class="ms-2">{{ address?.governorate }}</small></strong
              >
            </div>
            <p>{{ address?.addresss }}</p></label
          ><br />
        </div>

        <button
          *ngIf="!showAddingNewAddressForm"
          (click)="addNewAddress()"
          type="button"
          class="btn btn-success mb-2 small-button"
        >
          Add new address
        </button>
      </div>

      <div *ngIf="showAddingNewAddressForm" class="modal-body">
        <form [formGroup]="clientAddress" *ngIf="showAddingNewAddressForm">
          <div class="form-group">
            <label>City</label>
            <input formControlName="city" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label>Governorate</label>
            <input
              formControlName="governorate"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label>Address</label>
            <input formControlName="address" type="text" class="form-control" />
          </div>
        </form>
        <div class="d-flex justify-content-between align-items-center">
          <button
            title="Save the new address"
            class="btn btn-success small-button"
            [disabled]="!clientAddress.valid"
            *ngIf="showAddingNewAddressForm"
            (click)="saveAddress()"
          >
            Save address
          </button>

          <button
            title="Back to client addresses"
            class="btn btn-secondary small-button"
            *ngIf="showAddingNewAddressForm"
            (click)="backToClientAddressList()"
          >
            Back
          </button>
        </div>
      </div>

      <hr />

      <div class="modal-body mb-3" [formGroup]="phoneNoForm">
        <div>
          <div>
            <label>Phone number</label>
            <input
              formControlName="phoneNumber"
              type="text"
              class="form-control"
            />

            <span
              class="text-danger"
              *ngIf="
                phoneNoForm.controls.phoneNumber.errors?.invalidEmailOrPhone
              "
            >
              Please enter a valid Phone number
            </span>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button
          (click)="closePrompt()"
          type="button"
          data-bs-dismiss="modal"
          class="btn btn-secondary"
        >
          Close
        </button>
        <button
          (click)="save()"
          type="button"
          data-bs-dismiss="modal"
          class="btn btn-success"
        >
          Save Order
        </button>
      </div>
    </div>
  </div>
</div>
